import agent from '../../api/agent';

export default {
    fetchSettings: async ({commit}) => {
        try {
            const settings = await agent.settings.all();
            commit('SET_SETTINGS', settings);
        } catch (error) {
            console.log(error);
        }
    }
}